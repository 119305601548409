import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { StripePattern } from 'components/StripePattern';
import { InlineBlinkingLiveDot } from 'components/InlineBlinkingLiveDot';
import { LiveBlogContext } from 'lib/ContextTypes';
import { heroLayoutType as heroLayoutTypePropType } from 'lib/CustomPropTypes';
import i18next from 'i18next';

import './styles.themed.scss';

function LiveFlag({
  isBreaking = false,
  label = null,
  timestampWithUpdatedText = null,
  heroLayoutType = {},
}) {
  const { isLive } = useContext(LiveBlogContext);
  const showTimeStamp = timestampWithUpdatedText
    && (
      label !== 'SPECIAL REPORT'
      && label !== 'EXCLUSIVE'
      && label !== i18next.t('EXCLUSIVE')
      && label !== i18next.t('SPECIAL REPORT')
    );

  return (
    <div
      className={classNames(
        'live-flag',
        {
          'live-flag--active': isLive && !isBreaking,
          'live-flag--breaking': isBreaking,
          'live-flag--wide grid-col-push-1-xl': heroLayoutType?.wide,
        },
      )}
      data-test="live-flag"
      data-testid="live-flag"
    >
      <StripePattern />
      <div className="live-flag__container" data-test="live-flag__container" data-testid="live-flag__container">
        {isLive && (
          <div className="live-flag__dot-container">
            <InlineBlinkingLiveDot className="live-flag__dot" data-test="live-flag__dot" data-testid="live-flag__dot" />
          </div>
        )}
        {label && (
          <div className="live-flag__label">
            <div className="live-flag__label-text">
              {label}
            </div>
          </div>
        )}
        {showTimeStamp && (
          <div className="live-flag__timestamp" data-test="live-flag__timestamp" data-testid="live-flag__timestamp">
            <div>
              {timestampWithUpdatedText}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

LiveFlag.propTypes = {
  isBreaking: PropTypes.bool,
  label: PropTypes.string,
  timestampWithUpdatedText: PropTypes.node,
  heroLayoutType: heroLayoutTypePropType,
};


export default LiveFlag;
