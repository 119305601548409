import React from 'react';
import { useTracking } from 'lib/Hooks';
import {
  getAuthenticationState, launchAuthenticationModal, getCustomerId, isBedrockApiEnabled,
} from 'lib/authenticationUtils';

import { useMyNewsStore } from 'store';

import {
  AUTHENTICATED,
  UNAUTHENTICATED,
  ARTICLE,
  VIDEO,
  RECIPE,
} from 'lib/myNewsConstants';
import {
  video as videoPropType,
  recipe as recipePropType,
  article as articlePropType,
} from 'lib/CustomPropTypes';


/**
 * Custom hook to handle account workflows such as login and saving bookmarks.
 *
 * @param {object} params - The parameters for the hook.
 * @param {string} params.contentId - The ID of the content.
 * @param {string} params.contentType - The type of the content.
 * @returns {void}
 */
export function useAccountWorkflows({ contentId, contentType }) {
  const authenticationState = getAuthenticationState();
  const authenticationModal = launchAuthenticationModal();
  const createBookmark = useMyNewsStore((state) => state.createBookmark);
  const isNewLoginFlow = isBedrockApiEnabled();
  const customerId = getCustomerId();

  const track = useTracking('mbt_mynews_save_click');

  /**
   * Handles the action log in
   */
  function handleLogin() {
    authenticationModal();
    track({
      action: 'Login from URL param link',
      placement: 'URL param link',
      contentId,
    });
  }

  /**
   * Handles the action save Bookmark
   */
  async function handleSave() {
    createBookmark({
      contentId,
      contentType,
      customerId,
    });
    track({
      action: 'Saved',
      placement: 'URL param link',
      contentId,
    });
  }

  React.useEffect(() => {
    if (authenticationState !== AUTHENTICATED && authenticationState !== UNAUTHENTICATED) return;
    // trigger to save the content if url param is present
    // this is fetched client side to avoid production server caching issues
    const searchParams = new URLSearchParams(window?.location?.search);
    const accountParam = searchParams.get('account');
    if (!accountParam) return;

    if (accountParam.toLowerCase() === 'save' && authenticationState === AUTHENTICATED) {
      handleSave();
    }

    if ((accountParam.toLowerCase() === 'save' || isNewLoginFlow) && authenticationState === UNAUTHENTICATED) {
      handleLogin();
    }
  }, [authenticationState]);
}

/**
 * HOC to wrap a component with account workflows.
 * @param {React.Component} Component - The component to wrap.
 */
export const withAccountWorkflows = (Component) => {
  /**
   * AccountWorkflows
   * @param {object} props
   */
  const AccountWorkflows = (props) => {
    const {
      article,
      recipe,
      video,
    } = props;

    let contentId;
    let contentType;
    if (article) {
      contentId = article.id;
      contentType = ARTICLE;
    }
    if (recipe) {
      contentId = recipe.id;
      contentType = RECIPE;
    }
    if (video) {
      contentId = video.id;
      contentType = VIDEO;
    }

    useAccountWorkflows({ contentId, contentType });
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Component {...props} />;
  };

  if (Component.getInitialProps) {
    AccountWorkflows.getInitialProps = Component.getInitialProps;
  }

  AccountWorkflows.propTypes = {
    article: articlePropType,
    recipe: recipePropType,
    video: videoPropType,
  };

  return AccountWorkflows;
};
