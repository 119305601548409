import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Unibrow } from 'components/Unibrow';
import { ArticleContext } from 'lib/ContextTypes';
import { isSingleCardRoute } from 'lib/liveBlog';
import { heroLayoutType as heroLayoutTypePropType } from 'lib/CustomPropTypes';

import './styles.themed.scss';

const block = 'article-hero-headline';

/**
 * @param {object} props
 * @param {Node} props.children
 * @param {String} props.className
 * @param {Node} [props.dek]
 * @param {Boolean} props.isShoppingSection
 * @param {String} props.path
 * @param {String} props.vertical
 * @param {Boolean} [props.isLiveBlog]
 * @param {Node} [props.timestamp] - for recipes only
 * @param {Boolean} [props.isRecipe]
 * @returns {React.ReactElement}
 */
const ArticleHeroHeadline = ({
  additionalClasses = { htag: null, wrapper: null },
  children,
  dek = null,
  isLiveBlog = false,
  isRecipe = false,
  isPresentationStyleWide = false,
  heroLayoutType = {},
  path,
  timestamp = null,
}) => {
  const HeadlineTagName = isSingleCardRoute(path) ? 'h2' : 'h1';
  const {
    breakingNews, unibrow,
  } = useContext(ArticleContext);

  // for Breaking/Developing
  const isBreakingNews = breakingNews && !isLiveBlog;
  const isLiveBreakingNews = breakingNews || isLiveBlog;

  return (
    <div
      className={classNames(
        block,
        'layout-grid-item',
        {
          'grid-col-10-l': !isLiveBreakingNews && !heroLayoutType?.wide && !isRecipe,
          [`${block}--live-breaking`]: isLiveBreakingNews,
          [`${block}--recipe`]: isRecipe,
          'grid-col-11-xl grid-col-push-1-xl': heroLayoutType?.wide && !isPresentationStyleWide,
          'grid-col-8-xl grid-col-push-2-xl grid-col-10-m grid-col-push-1-m': isPresentationStyleWide,
          'grid-col-10-m grid-col-12-l grid-col-10-xl grid-col-push-1-m grid-col-push-0-l grid-col-push-1-xl': isRecipe,
        },
        additionalClasses?.wrapper,
      )}
    >
      {/* special unibrow added for breakpoint-x in Breaking template only, separate from grid item */}
      {isBreakingNews && (
        <Unibrow
          size="div"
          className={classNames(
            'article-hero__tax-term',
            { 'article-hero__tax-term--live-breaking': isLiveBreakingNews },
          )}
          unibrow={unibrow}
        />
      )}

      <HeadlineTagName
        className={classNames(
          `${block}__htag`,
          additionalClasses?.htag,
          'lh-none-print black-print',
          {
            [`${block}__htag--live-breaking`]: isLiveBreakingNews,
          },
        )}
        data-test="article-hero__headline"
      >
        {children}
      </HeadlineTagName>

      {dek}
      {timestamp}
    </div>
  );
};

ArticleHeroHeadline.propTypes = {
  additionalClasses: PropTypes.shape({
    htag: PropTypes.string,
    wrapper: PropTypes.string,
  }),
  children: PropTypes.node.isRequired,
  dek: PropTypes.node,
  isLiveBlog: PropTypes.bool,
  isRecipe: PropTypes.bool,
  isPresentationStyleWide: PropTypes.bool,
  heroLayoutType: heroLayoutTypePropType,
  path: PropTypes.string.isRequired,
  timestamp: PropTypes.node,
};

export default ArticleHeroHeadline;
